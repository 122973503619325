/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ErrorCode {
  ServerForbiddenRequest = 'e000001',
  ServerInvalidRequest = 'e000002',
  ServerUnauthorized = 'e000003',
  ServerInternalError = 'e000004',
  ServerNotFoundError = 'e000005',
  UserWorkflowAlreadyStarted = 'e001001',
  WorkflowCantFindGivenStepForUser = 'e001002',
  WorkflowStepAlreadyExistForTheGivenUser = 'e001003',
  UserLoginFailed = 'e001004',
  UserLoginNoPhoneGiven = 'e001005',
  UserNotFound = 'e001006',
  UserIsInvalid = 'e001007',
  UserWorkflowNotFound = 'e001008',
  UserLoginNoEmailGiven = 'e001009',
  UserErrorGettingBanks = 'e001010',
  UserLoginManyRequests = 'e001011',
  UserLoginError = 'e001012',
  UserErrorGettingBankByCode = 'e001013',
  UserProviderWrong = 'e001014',
  UserNoProviderForDomain = 'e001015',
  UserOTPValidationFailed = 'e001016',
  UserEmailOrPhoneTaken = 'e001017',
  DoctorNoProfession = 'e002001',
  DoctorNoUser = 'e002002',
  DoctorNotFound = 'e002003',
  DoctorInvalidCPF = 'e002004',
  DoctorCPFCRMMismatch = 'e002005',
  DoctorCRMInUse = 'e002006',
  DoctorCPFInUse = 'e002007',
  DoctorErrorSavingExtraSpecialty = 'e002008',
  ProfessionalMustExist = 'e003001',
  ProfessionalSyncError = 'e003002',
  ProfessionDocumentsListError = 'e003003',
  ProfessionalNotFound = 'e003004',
  ProfessionalErrorUpdatingScore = 'e003005',
  ProfessionDocumentMissingError = 'e003006',
  ProfessionalRegisterAlreadyTaken = 'e003007',
  ProfessionalRegisterNotFound = 'e003008',
  ProfessionalRegisterMismatch = 'e003009',
  ProfessionDoNotExist = 'e003010',
  ProfessionalRegisterInvalid = 'e003011',
  ProfessionSpecialtyNotFound = 'e003012',
  ProfessionalRegisterAlreadyEvaluated = 'e003013',
  ProfessionalRegisterEvaluationReasonError = 'e003014',
  ProfessionalDenyReasonError = 'e003015',
  ProfessionalRegisterMissingError = 'e003016',
  CompanyNoPersonForUser = 'e004001',
  CompanyNotFoundForUser = 'e004002',
  CompanyNotFound = 'e004003',
  CompanyPersonMismatch = 'e004004',
  CompanyNotValidAtSerPro = 'e004005',
  CompanyBankAccountGetValidationError = 'e004006',
  CompanyBankAccountValidationError = 'e004007',
  CompanyBankAccountNotFoundError = 'e004008',
  CompanySearchManyRequests = 'e004009',
  CompanyInvalidOperation = 'e004010',
  CompanyInvalidType = 'e004011',
  CompanyDocumentConfigListError = 'e004012',
  CompanyBankAccountUpsertError = 'e004013',
  CompanyPersonAlreadyConnectedToAnotherCompany = 'e004014',
  CompanyInvalidCNPJ = 'e004015',
  CompanyCannotDelete = 'e004016',
  CompanyDocumentMissingError = 'e004017',
  CompanyMemberNotFound = 'e004018',
  CompanyMemberNotCreated = 'e004019',
  NoCompanyMembersForGivenCompany = 'e004020',
  CompanyMemberNotUpdated = 'e004021',
  NoCompanyMembersForGivenCompanyAtSerPro = 'e004022',
  CompanyBPNotFound = 'e004023',
  SendNewTaxFormTooManyRequests = 'e004024',
  NoCompanyTaxFormToReplicate = 'e004025',
  CompanyTaxFormReplicateError = 'e004026',
  PersonPhoneAlreadyTaken = 'e005001',
  PersonEmailAlreadyTaken = 'e005002',
  PersonErrorGettingFacialRecognitionData = 'e005003',
  PersonRGAlreadyTaken = 'e005004',
  PersonCPFCanNotChange = 'e005005',
  PersonInvalidCPFStatus = 'e005006',
  PersonCPFInUserByAnotherUser = 'e005007',
  PersonInvalidCPF = 'e005008',
  PersonReportDataNotFound = 'e005009',
  PersonNotFound = 'e005010',
  PersonReportDocumentTypeError = 'e005011',
  AdminProfessionalDataMissing = 'e006001',
  AdminErrorCreatingBussinessPartner = 'e006002',
  AdminErrorQueryingBussinessPartner = 'e006003',
  AdminErrorGettingProfessionalFullInfo = 'e006004',
  AdminErrorRetrievingShiftManagerGroups = 'e006005',
  AdminErrorApprovingDoctorExtraSpecialty = 'e006006',
  AdminErrorDenyingDoctorExtraSpecialty = 'e006007',
  AdminErrorGettingDoctorExtraSpecialty = 'e006008',
  AdminErrorGettingProviders = 'e006009',
  AdminErrorGettingProfessionalComplianceInfo = 'e006010',
  AdminErrorGettingProfessionalLogsInfo = 'e006011',
  AdminErrorRetrievingBukrsList = 'e006012',
  AdminErrorProfessionalNotFound = 'e006013',
  AdminErrorGettingRegisteringProfessional = 'e006014',
  AdminErrorGdmStepApproval = 'e006015',
  AdminProfessionalNotApprovedCantBeReturned = 'e006016',
  AdminProfessionalNotEvaluatedCantBeReturned = 'e006017',
  NeowayErrorRetrievingScore = 'e007001',
  NeowayErrorTriggeringGetAllCompanyScore = 'e007002',
  NeowayQuotaError = 'e007003',
  SupAuthPersonNotFound = 'e008001',
  SupAuthPersonWorkflowInvalid = 'e008002',
  UserActionTokenUsed = 'e009001',
  UserActionTokenWrongEntity = 'e009002',
  ContractSignerMissingInfo = 'e010001',
  ContractMissingInfo = 'e010002',
  ContractReviewerInvalidCPF = 'e010003',
  ContractReviewerNotFound = 'e010004',
  ContractDocumentGenerationError = 'e010005',
  ContractCreationError = 'e010006',
  ContractApprovalCompetencyInsertError = 'e010007',
  ContractApprovalCompetencyUpdateError = 'e010008',
  ContractApprovalCompetencyNotFound = 'e010009',
  ContractApprovalError = 'e010010',
  ContractPendingAssignmentNotFound = 'e010011',
  ContractCancellationError = 'e010012',
  ContractDocumentNotFound = 'e010013',
  ContractNotFound = 'e010014',
  ContractNotApproved = 'e010015',
  ContractStatusError = 'e010016',
  CommentNotFoundError = 'e011001',
  CommentUnexpectedEntity = 'e011002',
  CommentUnauthorizedEditError = 'e011003',
  ProfessionNotFound = 'e012001',
  MarketplaceProfessionalAlreadyExists = 'e013001',
  PersonBankAccountGetValidationError = 'e014001',
  PersonBankAccountValidationError = 'e014002',
  PersonBankAccountNotFoundError = 'e014003',
  AccreditationNotFound = 'e016001',
}
